/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { ContentPageLayout } from '@components/Layout';
import { useIntl } from 'gatsby-plugin-intl';
import { observer } from 'mobx-react';
import { FAULT_REPORT } from '../../constants/other-form-fields';
import { FaultReportForm } from '@components/FaultReportForm';
import { TextSection } from '@components/TextSection';
import { PAGE_NAV_ITEMS } from '../../constants/page-nav-items';
import { NaviButtons } from '@components/NaviButtons';
import { useStores } from '../../stores/index';

interface IFaultReportFormPage {
  data: any;
}

const FaultReportFormPage: FC<IFaultReportFormPage> = observer(() => {
  const { formatMessage } = useIntl();
  const {
    agreementStore: { selectedAgreement },
  } = useStores();
  return (
    <ContentPageLayout wideContent>
      <TextSection title={formatMessage({ id: 'fields.label.faultReport.makeFaultReport' })} afterTitle={<NaviButtons items={PAGE_NAV_ITEMS.faultReportForm} />} />
      <FaultReportForm id="fault-report" fields={FAULT_REPORT} />
    </ContentPageLayout>
  );
});

export default FaultReportFormPage;
